<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title"><a-icon type="share-alt" />流水认领</div>
    </div>
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :hide-row-selection="true"
      :current-page="pageNumber"
      :scroll="{ x: 800 }"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :hide-row-selection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <a-input
            v-model="keyword"
            placeholder="请输入付方账号或付方名称关键字"
            allowClear
          />
        </a-col>
      <a-col :span="4">
        <a-input-number v-model="creditAmount" placeholder="请输入付款金额" style="width: 100%" />
      </a-col>
      <a-col :span="6">
        <a-range-picker  v-model="dueDay" :format="dueDayFormat" :valueFormat="dueDayFormat" :placeholder="dueDayplaceholder" />
      </a-col>
        <a-col :span="6">
          <a-button
            type="primary"
            @click="search"
            v-if="true"
          >
            <a-icon type="search" />
            {{ l("搜索") }}
          </a-button>
          <a-button @click="clearFilterAndRefresh">
            {{ l("重置") }}
          </a-button>
        </a-col>
      </a-row>
    </ele-table>

    <!-- <div class="modal-footer">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'">
        <a-icon type="save" />
        {{ l("确定") }}
      </a-button>
    </div> -->

    <a-modal
      centered
      :title="inputModal.title"
      :visible="inputModal.visible"
      :confirm-loading="inputModal.confirmLoading"
      :maskClosable="inputModal.maskClosable"
      :destroyOnClose="inputModal.destroyOnClose"
      :width="inputModal.width"
      @ok="inputModal.confirm"
      @cancel="inputModal.cancel"
      :cancelText="inputModal.cancelText"
      :okText="inputModal.okText"
    >
      <div style="text-align: center">
        {{ inputModal.content }}
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";

export default {
  name: "bank-account",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      commonService: null,
      selectedRowKeys: [],
      selectedRows: [],
      filteredInfo: null,
      sortedInfo: null,
      keyword: "",
      creditAmount:undefined,
      dueDay:"",
      dueDayplaceholder:["开始交易日","开始交易日"],
      dueDayFormat:'YYYYMMDD',
      rParams: { type: 0 },
      organizationUnitId: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("流水编号"),
          dataIndex: "billDate",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "billDate" },
        },
        {
          title: this.l("交易日"),
          dataIndex: "dueDay",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "dueDay" },
        },
        {
          title: this.l("付款金额"),
          dataIndex: "creditAmount",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "creditAmount" },
        },
        {
          title: this.l("付方名称"),
          dataIndex: "payerName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "payerName" },
        },
        {
          title: this.l("付方账号"),
          dataIndex: "payerAccount",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "payerAccount" },
        },
        {
          title: this.l("账单余额"),
          dataIndex: "balance",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "balance" },
        },

        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "actions" },
          width: 100,
        },
      ],
      rejectColumns: [],
      // 用户数据
      tableData: [],
      actionsType: {},
      getDataParams: [],
      // modal
      inputModal: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 400,
        title: "申请开票",
        url: "",
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.inputModal.confirm = () => {};
          this.inputModal.visible = false;
        },
      },
    };
  },
  computed: {},
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this.getData();
  },

  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData() {
      this.spinning = true;
      console.log(this.dueDay)
      let params = {
        organizationUnitId: this.params.organizationUnitId,
        status: this.params.type,
        // organizationUnitId: '8',
        filterText: this.filterText,
        keyword: this.keyword,
        creditAmount:this.creditAmount,
        startDueDay:this.dueDay[0],
        endDueDay:this.dueDay[1],
        sorting: this.request.sorting,
        skipCount: this.request.skipCount,
        maxResultCount: this.request.maxResultCount,
        billId: this.params.id,
      };
      try {
        // this.params.type == 0 ? this.l("流水认领") : this.l("流水退回"),
        let postUrl =
          this.params.type == 0
            ? "/api/services/app/Bill/GetBankBillPaged"
            : "/api/services/app/Bill/GetClaimantBillPaged";

        let res = await this.commonService.get({
          url: postUrl,
          params: params,
        });
        this.spinning = false;
        this.tableData = res.items.map((o) => {
          return {
            ...o,
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name:
                this.params.type == 0 ? this.l("流水认领") : this.l("流水退回"),
              icon: "edit",
              fn: (data) => {
                if (this.params.type == 0) {
                  _this._claimantBill(data, "edit");
                } else {
                  _this._rejectClaimantBill(data, "edit");
                }
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.keyword = "";
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(selectedRows);
    },
    async _claimantBill(item) {
      let _this = this;
      _this.saving = true;

      this.inputModal.title = "流水认领";
      this.inputModal.content = `是否对 ${item.payerName} 进行流水认领？`;
      this.inputModal.visible = true;
      this.inputModal.confirm = async function () {
        _this.spinning = true;
        _this.saving = true;
        _this.inputModal.confirmLoading = true
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/ClaimantBill",
            params: {
              billId: _this.params.id,
              id: item.id,
              bankNumber: item.bankNumber,
              dueDay: item.dueDay,
              creditAmount: item.creditAmount,
              payerName: item.payerName,
              payerAccount: item.payerAccount,
              balance: item.balance,
            },
          });
          _this.spinning = false;
          _this.saving = false;
          _this.inputModal.visible = false;
          _this.inputModal.confirmLoading = false
          _this.getData();
          _this.notify.success(_this.l("流水确认成功"));
        } catch (error) {
          console.log(error);
          _this.spinning = false;
          _this.saving = false;
          _this.inputModal.confirmLoading = false
          _this.inputModal.visible = false
        }
      };
    },
    async _rejectClaimantBill(item) {
      let _this = this;
      _this.saving = true;

      this.inputModal.title = "流水退回";
      this.inputModal.content = `是否对 ${item.payerName} 进行流水退回？`;
      this.inputModal.visible = true;
      this.inputModal.confirm = async function () {
        _this.spinning = true;
        _this.saving = true;
        _this.inputModal.confirmLoading = true
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/ClaimantBillBack",
            params: {
              claimantId: item.id,
            },
          });
          _this.spinning = false;
          _this.saving = false;
          _this.inputModal.visible = false;
          _this.inputModal.confirmLoading = false
          _this.getData();
          _this.notify.success(_this.l("流水退回"));
        } catch (error) {
          console.log(error);
          _this.spinning = false;
          _this.saving = false;
          _this.inputModal.confirmLoading = false
          _this.inputModal.visible = false
        }
      };
    },
            search() {
     this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.getData();
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}

.opeattion-container {
  margin: 20px 0;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
